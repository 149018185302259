html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  background: #000;
  //background-image: url('assets/images/bg_slide_8.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

#root>div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.brt-logo {
  position: relative;
  z-index: 999;
  max-width: 300px;

  @media(min-width: 768px) {
    max-width: 400px;
  }
}